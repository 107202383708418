var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-list",
                    { attrs: { shaped: "" } },
                    [
                      _c("v-subheader", [_vm._v("ROLES")]),
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        _vm._l(_vm.roles, function (role, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(role)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(role.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showable,
                      expression: "showable",
                    },
                  ],
                  staticClass: "mb-4",
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-container", [
                        _c("h3", [_vm._v(_vm._s(_vm.editedItem.name))]),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.roleDescriptions[_vm.editedItem.id]
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showConfigurablePermissions,
                      expression: "showConfigurablePermissions",
                    },
                  ],
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { md: "4", cols: "12" } }, [
                                _c("label", [
                                  _vm._v("CONFIGURABLE PERMISSIONS"),
                                ]),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                { attrs: { md: "2", cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      attrs: { dark: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveItem()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-content-save"),
                                      ]),
                                      _vm._v("Save "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 9,
                                  expression: "editedItem.id == 9",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to reschedule / cancel trips",
                                      value:
                                        _vm.roleConfig.approver.rescheduleTrips,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.approver.rescheduleTrips,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.approver,
                                          "rescheduleTrips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.approver.rescheduleTrips",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 10,
                                  expression: "editedItem.id == 10",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to reschedule / cancel trips",
                                      value:
                                        _vm.roleConfig.siteAdmin
                                          .rescheduleTrips,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.siteAdmin
                                          .rescheduleTrips,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.siteAdmin,
                                          "rescheduleTrips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.siteAdmin.rescheduleTrips",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 13,
                                  expression: "editedItem.id == 13",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: "Allow to change drivers",
                                      value:
                                        _vm.roleConfig.schoolFinance
                                          .changeDrivers,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.schoolFinance
                                          .changeDrivers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.schoolFinance,
                                          "changeDrivers",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.schoolFinance.changeDrivers",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: "Allow to enter mileage / time",
                                      value:
                                        _vm.roleConfig.schoolFinance
                                          .enterMileage,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.schoolFinance
                                          .enterMileage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.schoolFinance,
                                          "enterMileage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.schoolFinance.enterMileage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 14,
                                  expression: "editedItem.id == 14",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to fully manage invoices for their locations",
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .manageInvoices,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .manageInvoices,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "manageInvoices",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.manageInvoices",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to select any vehicle location for vehicle assignment",
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .anyVehicleLocation,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .anyVehicleLocation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "anyVehicleLocation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.anyVehicleLocation",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to see listing of available vehicles with the 'All Locations' button",
                                      disabled:
                                        _vm.roleConfig.vehicleOwner
                                          .assignAllLocations,
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .showAllLocations,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .showAllLocations,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "showAllLocations",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.showAllLocations",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to assign vehicles from the 'All Locations' button",
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .assignAllLocations,
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.roleConfig.vehicleOwner
                                          .assignAllLocations
                                          ? (_vm.roleConfig.vehicleOwner.showAllLocations = true)
                                          : null
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .assignAllLocations,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "assignAllLocations",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.assignAllLocations",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to use vehicle override button when assigning vehicles",
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .overrideVehicle,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .overrideVehicle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "overrideVehicle",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.overrideVehicle",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to use driver override button when assigning drivers",
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .overrideDriver,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .overrideDriver,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "overrideDriver",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.overrideDriver",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to reschedule / cancel trips",
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .rescheduleTrips,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.vehicleOwner
                                          .rescheduleTrips,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.vehicleOwner,
                                          "rescheduleTrips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.vehicleOwner.rescheduleTrips",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 15,
                                  expression: "editedItem.id == 15",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to fully manage invoices for their locations",
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .manageInvoices,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .manageInvoices,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "manageInvoices",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.manageInvoices",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to select any vehicle location for vehicle assignment",
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .anyVehicleLocation,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .anyVehicleLocation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "anyVehicleLocation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.anyVehicleLocation",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to see listing of available vehicles with the 'All Locations' button",
                                      disabled:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .assignAllLocations,
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .showAllLocations,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .showAllLocations,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "showAllLocations",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.showAllLocations",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to assign vehicles from the 'All Locations' button",
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .assignAllLocations,
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .assignAllLocations
                                          ? (_vm.roleConfig.specialNeedsVehicleOwner.showAllLocations = true)
                                          : null
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .assignAllLocations,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "assignAllLocations",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.assignAllLocations",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to use vehicle override button when assigning vehicles",
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .overrideVehicle,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .overrideVehicle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "overrideVehicle",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.overrideVehicle",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to use driver override button when assigning drivers",
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .overrideDriver,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .overrideDriver,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "overrideDriver",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.overrideDriver",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to reschedule / cancel trips",
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .rescheduleTrips,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.specialNeedsVehicleOwner
                                          .rescheduleTrips,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig
                                            .specialNeedsVehicleOwner,
                                          "rescheduleTrips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.specialNeedsVehicleOwner.rescheduleTrips",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 16,
                                  expression: "editedItem.id == 16",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to view Trips indicating students have health concerns from ALL locations",
                                      value:
                                        _vm.roleConfig.nurse.viewAllLocations,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.nurse.viewAllLocations,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.nurse,
                                          "viewAllLocations",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.nurse.viewAllLocations",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editedItem.id == 19,
                                  expression: "editedItem.id == 19",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label:
                                        "Allow to reschedule / cancel trips",
                                      value:
                                        _vm.roleConfig.requester
                                          .rescheduleTrips,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.requester
                                          .rescheduleTrips,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.requester,
                                          "rescheduleTrips",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.requester.rescheduleTrips",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0",
                                    attrs: {
                                      label: "Allow to enter mileage / hours",
                                      value:
                                        _vm.roleConfig.requester.enterMileage,
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.roleConfig.requester.enterMileage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.roleConfig.requester,
                                          "enterMileage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "roleConfig.requester.enterMileage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showable,
                      expression: "showable",
                    },
                  ],
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.editedItem.id > 0
                        ? _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Trip Request")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "View",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.view
                                                .triprequest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.view,
                                                "triprequest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.view.triprequest",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Add",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.add
                                                .triprequest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.add,
                                                "triprequest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.add.triprequest",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Edit",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.edit
                                                .triprequest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.edit,
                                                "triprequest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.edit.triprequest",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Delete",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.delete
                                                .triprequest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.delete,
                                                "triprequest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.delete.triprequest",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Assignment")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "View",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.view
                                                .assignment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.view,
                                                "assignment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.view.assignment",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Add",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.add
                                                .assignment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.add,
                                                "assignment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.add.assignment",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Edit",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.edit
                                                .assignment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.edit,
                                                "assignment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.edit.assignment",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Delete",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.delete
                                                .assignment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.delete,
                                                "assignment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.delete.assignment",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Invoice")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "View",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.view.invoice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.view,
                                                "invoice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.view.invoice",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Edit",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.isInvoiceEditable,
                                            callback: function ($$v) {
                                              _vm.isInvoiceEditable = $$v
                                            },
                                            expression: "isInvoiceEditable",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Setup Tables")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "View",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.view.tables,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.view,
                                                "tables",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.view.tables",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Add",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.add.tables,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.add,
                                                "tables",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.add.tables",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Edit",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.edit.tables,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.edit,
                                                "tables",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.edit.tables",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Delete",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.delete.tables,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.delete,
                                                "tables",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.delete.tables",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Users")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "View",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.view.user,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.view,
                                                "user",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.view.user",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Add",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.rolePermisssion.add.user,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.add,
                                                "user",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.add.user",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Edit",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.edit.user,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.edit,
                                                "user",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.edit.user",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Delete",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.delete.user,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.delete,
                                                "user",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.delete.user",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", [
                                    _c("h3", [_vm._v("Roles")]),
                                    _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "View",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.view.role,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.view,
                                                "role",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.view.role",
                                          },
                                        }),
                                        _c("v-checkbox", {
                                          attrs: {
                                            dense: "",
                                            label: "Edit",
                                            readonly: "",
                                          },
                                          model: {
                                            value:
                                              _vm.rolePermisssion.edit.role,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.rolePermisssion.edit,
                                                "role",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "rolePermisssion.edit.role",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { "offset-md": "3" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }